<script setup lang="ts">
import { FormKitConfig } from '@formkit/core'
import { getSessionContext } from '@shopware-pwa/api-client'
import { SessionContext } from '@shopware-pwa/types'

const { apiInstance } = useShopwareContext()
const { changeLanguage } = useInternationalization()
const { getLanguageId, languageCode } = useLocale()
const { refreshSessionContext } = useSessionContext()

const languageId = await getLanguageId()
if (languageId) {
  await changeLanguage(languageId)
  apiInstance.update({ languageId })
}

const { data: sessionContextData } = await useAsyncData('sessionContext', async () => {
  return await getSessionContext(apiInstance)
})

useSessionContext(sessionContextData.value as SessionContext)
useBreadcrumbs()
useNotifications()
useAddress()

const { refreshCart } = useCart()

const formkitConfig = inject(Symbol.for('FormKitConfig')) as FormKitConfig

onMounted(() => {
  refreshCart()
  refreshSessionContext()
  formkitConfig.locale = languageCode.value

  // Unregister old service worker
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.getRegistrations().then((registrations) => {
      for (const registration of registrations) {
        // This will unregister the old service worker
        registration.unregister()
      }
    })
  }
})
</script>
<template>
  <NuxtLayout>
    <PageLoadingIndicator />
    <NuxtPage />
  </NuxtLayout>
</template>
